import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import Seo from "~common/components/Seo"

const Landing = ({ data }) => {
  const { mdx } = data

  const {
    frontmatter: { title, description },
    body,
  } = mdx

  return (
    <>
      <Seo
        title={title}
        titleTemplate={`%s | Almagesto`}
        description={description}
      />
      <MDXRenderer>{body}</MDXRenderer>
    </>
  )
}

export const pageQuery = graphql`
  query LandingByPath($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      body
      frontmatter {
        path
        title
        description
      }
    }
  }
`

export default Landing
